import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Package: @firebolt-dev/snap",
      description: "A Firebolt utility for rendering jsx to an image."
    }), "\n", _jsx(_components.h1, {
      children: "@firebolt-dev/snap"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "@firebolt-dev/snap"
      }), " package provides a utility for rendering jsx to an image. It can be used to dynamically create images for pages in your app on the fly, that can then be used in open graph meta tags."]
    }), "\n", _jsx(_components.h2, {
      children: "Usage"
    }), "\n", _jsxs(_components.p, {
      children: ["Create a ", _jsx(_components.a, {
        href: "/docs/ref/handler-js",
        children: "handler"
      }), " to render your images"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/opengraph.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { css } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " 'firebolt'"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " snap "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " '@firebolt-dev/snap'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " async"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " get"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "ctx"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ") {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "title"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ctx.params"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " snap"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "      css"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "css"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "`"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        position: absolute;"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        inset: 0;"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        display: flex;"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        align-items: center;"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        justify-content: center;"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "      `"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    >"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">{title}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["In this example, a request such as ", _jsx(_components.code, {
        children: "GET /opengraph?msg=Hello"
      }), " will respond with a 1200x630 png with the text \"Hello\" centered inside of it."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can now use this in your meta tags to generate unique images for each of your pages. For example by using a ", _jsx(_components.code, {
        children: "<Meta>"
      }), " component or similar:"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "components/Meta.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " Meta"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "({ "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "title"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " }) {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: " image"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " ="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " `${"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "process"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "env"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "PUBLIC_DOMAIN"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "}/opengraph?title=${"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "title"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "}`"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ("
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <>"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "title"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">{title}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "title"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "meta"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " name"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'og:title'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " content"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{title} />"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "meta"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " name"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'og:image'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " content"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{image} />"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "meta"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " name"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'twitter:title'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " content"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{image} />"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "meta"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " name"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'twitter:image'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " content"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{image} />"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      {"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6A737D",
              "--shiki-dark": "#6A737D"
            },
            children: "/* description etc... */"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </>"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.h2, {
      children: "Caching"
    }), "\n", _jsx(_components.p, {
      children: "JSX is rendered to a string and sent to puppeteer to be screenshot."
    }), "\n", _jsxs(_components.p, {
      children: ["A hash is generated based on the HTML string and the image is stored at ", _jsx(_components.code, {
        children: ".firebolt/snap/[hash].png"
      }), ".\nRequests for each unique image are only screenshot once using puppeteer and then subsequent requests for the same image are served from the cache."]
    }), "\n", _jsx(_components.h2, {
      children: "Images, Fonts etc"
    }), "\n", _jsxs(_components.p, {
      children: ["When rendering images or using fonts, we recommend injecting them as ", _jsx(_components.code, {
        children: "base64"
      }), " data urls for better results."]
    }), "\n", _jsx(_components.p, {
      children: "Firebolt makes this easy, as imports for media and fonts return base64 data urls automatically:"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/opengraph.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { css } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " 'firebolt'"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " snap "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " '@firebolt-dev/snap'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " background "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " './background.png'"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " robotoFlex "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " './roboto-flex.woff2'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " async"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " get"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "ctx"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ") {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " snap"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "      css"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "css"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "`"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        @font-face {"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "          font-family: 'Roboto Flex';"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "          src: url(${"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "robotoFlex"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "}) format('woff2');"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        }"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "        background-image: url(${"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "background"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "});"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "      `"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    >"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      {"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6A737D",
              "--shiki-dark": "#6A737D"
            },
            children: "/* content */"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.h2, {
      children: "Options"
    }), "\n", _jsxs(_components.p, {
      children: ["By default all rendered images are ", _jsx(_components.code, {
        children: "1200 x 630"
      }), " which is the recommended size for images utilizing meta tags such as ", _jsx(_components.code, {
        children: "og:image"
      }), " and ", _jsx(_components.code, {
        children: "twitter:image"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can modify this size by providing options as a second argument to ", _jsx(_components.code, {
        children: "snap"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " snap"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "(<"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " />, { width: "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "600"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ", height: "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "315"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " })"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.h2, {
      children: "Environment"
    }), "\n", _jsxs(_components.p, {
      children: ["This plugin relies on ", _jsx(_components.a, {
        href: "https://pptr.dev/",
        children: "puppeteer"
      }), " and requires an environment that supports it."]
    }), "\n", _jsxs(_components.p, {
      children: ["Check out our ", _jsx(_components.a, {
        href: "https://github.com/firebolt-dev/firebolt/blob/main/packages/website/Dockerfile",
        children: "website"
      }), " repo to see how we configured this for ", _jsx(_components.a, {
        href: "https://fly.io",
        children: "fly.io"
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
